<template>
  <!-- 横屏 -->
  <horizontalScreen class="v-listen-word-index">
    <!-- <scaleWrap> -->
    <div class="home">
      <div class="word-container">
        <span :class="{ 'active': item.active }" class="word" @click="wordClickHandler($event, item, index)"
          v-for="(item, index) in showWordList" :key="index">{{ index + 1 }}</span>
      </div>
      <img class="logo" src="@/assets/index/img-logo.png" />
      <img class="game-logo" src="@/assets/listenWord/img-ltzc.png" />
      <img @click="backHandler" class="btn-back" src="@/assets/index/btn-back.png" />
      <click-audio ref="clickAudio"></click-audio>
      <div class="btn-book">{{ albumName }}</div>
      <img class="btn-title" src="@/assets/listenText/btn-title.png" />
      <div class="btn-bottom-box">
        <img class="btn-all" @click="allHandler" src="@/assets/listenText/btn-all.png" />
        <img class="btn-confirm" @click="confirmHandler" src="@/assets/listenText/btn-confirm.png" />
      </div>
    </div>
    <!-- </scaleWrap> -->
  </horizontalScreen>
</template>
<script>
import anime from 'animejs/lib/anime.es.js'
import horizontalScreen from '@/components/horizontal-screen'
import { isEmpty } from 'lodash'
import { mapActions, mapMutations, mapGetters } from 'vuex'
import ClickAudio from '@/components/clickAudio.vue'

export default {
  name: 'ListenWordIndex',
  components: {
    horizontalScreen, ClickAudio
  },
  computed: {
    ...mapGetters(['albumName', 'albumId'])
  },
  data () {
    return {
      showWordList: []
    }
  },
  created () {
    this.SET_CARD_GRADE_IDS([])
    this.fetchList()
  },
  methods: {
    isEmpty,
    ...mapMutations(['SET_CARD_GRADE_IDS']),
    ...mapActions(['getListenWordList']),
    async fetchList () {
      this.$loading.show('数据加载中。。。')
      try {
        const res = await this.getListenWordList({
          albumId: this.albumId,
          current: 1,
          pageSize: 20
        })
        console.log('getListenWordList', res)
        this.showWordList = res.result
      } finally {
        this.$loading.hide()
      }
    },
    async backHandler () {
      await this.$refs.clickAudio.playAudio()
      this.$router.push({ name: 'Book' })
    },
    // 字点击
    async wordClickHandler (event, word, index) {
      const parentNode = event.target

      anime({
        targets: parentNode,
        scale: [
          { value: 1, duration: 100 },
          { value: 1.1, duration: 100 },
          { value: 1, duration: 100 }
        ],
        complete: () => {
          parentNode.style.transform = 'none'
        }
      });
      this.$set(this.showWordList[index], 'active', !this.showWordList[index].active)
      this.$refs.clickAudio.playAudio()
    },
    async allHandler () {
      await this.$refs.clickAudio.playAudio()
      const gradeIds = this.showWordList.map(({ gradeId }) => gradeId)
      this.SET_CARD_GRADE_IDS(gradeIds)
      // this.SET_LISTEN_WORD_RADIO(objs)
      this.$router.push({ name: 'ListenWordCourse' })

    },
    async confirmHandler () {
      await this.$refs.clickAudio.playAudio()
      const gradeIds = this.showWordList.filter(item => item.active).map(({ gradeId }) => gradeId)
      if (gradeIds.length) {
        this.SET_CARD_GRADE_IDS(gradeIds)
        // this.SET_LISTEN_WORD_RADIO(objs)
        this.$router.push({ name: 'ListenWordCourse' })
      } else {
        this.$myToast({ msg: '请至少选择一课' })
      }

    }
  }
}
</script>

<style lang="scss" scoped>
.v-listen-word-index {

  // // font-family: 'Alibaba-PuHuiTi-Bold';
  // font-family: 'FZHuPo-M04S';
  audio {
    position: absolute;
    top: -1000px;
    left: -1000px;
    z-index: -1;
  }

  .home {
    height: 100%;
    width: 100%;
    background: url(~@/assets/index/bg-index.png) center center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    overflow: hidden;
  }

  // .logo {
  //   width: 12px;
  //   left: 5px;
  //   top: 10PX;
  //   position: fixed;
  // }
  .game-logo {
    width: 38px;
    left: 48px;
    top: 5PX;
    position: fixed;
  }

  .btn-back {
    position: fixed;
    width: 65.5px;
    right: 10PX;
    top: 5PX;
  }

  .btn-book {
    color: #6eabf3;
    font-size: 22px;
    z-index: 9;
    position: fixed;
    width: 149px;
    height: 44.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url('~@/assets/listenText/bg-book.png') center center no-repeat;
    background-size: 100%;
    top: 30px;
    right: 100px;
  }

  .btn-title {
    z-index: 9;
    position: fixed;
    width: 161.6px;
    height: 49.5px;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
  }

  .btn-bottom-box {
    z-index: 9;
    position: fixed;
    bottom: 15px;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .btn-all {
      width: 131px;
      height: 48.5px;
    }

    .btn-confirm {
      margin-left: 60px;
      width: 138.5px;
      height: 54px;
    }
  }

  .word-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    width: 70%;
    margin: 70px auto 0;
    font-weight: bolder;

    .word {
      margin: 10px 0 0 10px;
      font-size: 40px;
      color: #821115;
      width: 60px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: url('~@/assets/listenText/btn-class.png') center center no-repeat;
      background-size: 100%;

      &.active {
        background: #28cb18;
        border-radius: 16px;
      }
    }
  }

}</style>
